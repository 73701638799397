import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ValueWithLineClamp = ({ children, sx, lines = 2, color = 'currentColor', openOnHover = false, textSx = {}, ...props }) => {
    return (
        <Box
            {...props}
            sx={{
                overflow: 'hidden',
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx
            }}
        >
            <Typography
                sx={{
                    ...textSx,
                    fontSize: '14px',
                    lineHeight: '16px',
                    fontWeight: '500',
                    WebkitLineClamp: lines,
                    whiteSpace: 'pre-wrap',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    color,
                    '&:hover': openOnHover && {
                        WebkitLineClamp: 'unset'
                    }
                }}
            >
                {children}
            </Typography>
        </Box>
    );
};

export default ValueWithLineClamp;
