import React, { useState } from 'react';
import { useGetNotificationQuery, useMoveToArchiveAllMutation, useMoveToArchiveMutation } from 'services/notification';
import NotificationList from './NotificationList';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const DEFAULT_TOP = 50;

const NOTIFICATION_TYPE = {
    Error: '#A61C23',
    Warning: '#D89615',
    Info: '#12A8A8'
};

const Notifications = () => {
    const [isArchived, setIsArchived] = useState(false);
    const [top, setTop] = useState(DEFAULT_TOP);
    const [topNotificationArhived, setTopNotificationArhived] = useState(DEFAULT_TOP);
    const [archiveAll, { isLoading: isLoadingArchiving }] = useMoveToArchiveAllMutation();

    const userId = useSelector((state) => state.auth.userId);

    const { data, isLoading } = useGetNotificationQuery({ userId: userId, isArchived: false, top: top }, { skip: !userId });
    const { data: arhivedNitifications, isLoading: isLoadingArhived } = useGetNotificationQuery(
        { userId: userId, isArchived: true, top: topNotificationArhived },
        { skip: !userId }
    );

    const toggleIsArchived = () => setIsArchived((prev) => !prev);

    const handleScrollEnd = () => {
        if (isArchived) {
            if (topNotificationArhived > arhivedNitifications?.value?.length) return;
            setTopNotificationArhived((prev) => {
                return prev + DEFAULT_TOP;
            });
        } else {
            if (top > data?.value?.length) return;

            setTop((prev) => {
                return prev + DEFAULT_TOP;
            });
        }
    };

    const lastNotificationType = data?.value?.[0]?.Type;

    const notifacationCountColor = lastNotificationType ? NOTIFICATION_TYPE[lastNotificationType] || '#12A8A8' : '#12A8A8';

    const items = isArchived ? arhivedNitifications?.value : data?.value;

    const handleArchiveAll = () => {
        archiveAll().unwrap();
    };
    return (
        <NotificationList
            notifacationCountColor={notifacationCountColor}
            arhivedNitifications={arhivedNitifications?.[`@odata.count`]}
            notArhivedNotifacationCount={data?.[`@odata.count`]}
            isLoading={isLoading || isLoadingArhived || isLoadingArchiving}
            notifications={items}
            toggleIsArchived={toggleIsArchived}
            onArchiveAll={handleArchiveAll}
            onScrollEnd={handleScrollEnd}
        />
    );
};

export default Notifications;
