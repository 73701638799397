import { toast } from "react-toastify";
import { api } from "./api";
import { createBatchRequest } from "./createBatchRequest";

export const odataBatchApi = api.injectEndpoints({
   endpoints: (builder) => ({
      odataBatch: builder.mutation({
         query: (callsArray) => {
            const [batchRequest, boundary] = createBatchRequest(callsArray);
            return {
               url: '$batch',
               method: 'POST',
               body: batchRequest,
               headers: {
                  'Accept': '*',
                  'Content-Type': `multipart/mixed; boundary=${boundary}`,
               },
               responseHandler: (response) => {
                  return response?.body?.getReader().read().then(({ done, value }) => {
                     const text = new TextDecoder().decode(value);
                     let { errors, objects } = parseBatchRespoonse(text)
                     if (errors.length) {
                        errors.forEach(err => {
                           toast.error(`MESSAGE: ${err?.error?.message} CODE: ${err?.error?.code}`)
                        })
                        return { status: false }
                     } else {
                        return { status: true, value: objects }
                     }
                  })
               }
            };
         },
      }),
   })
});

export const { useOdataBatchMutation } = odataBatchApi;

function extractAndParseObjects(text) {
   const objects = [];

   let startIndex = text.indexOf('{');
   while (startIndex !== -1) {
      let openBraces = 1;
      let endIndex = startIndex + 1;
      while (openBraces > 0 && endIndex < text.length) {
         if (text[endIndex] === '{') {
            openBraces++;
         } else if (text[endIndex] === '}') {
            openBraces--;
         }
         endIndex++;
      }
      if (openBraces === 0) {
         const jsonString = text.slice(startIndex, endIndex);
         try {
            const parsedObject = JSON.parse(jsonString);
            objects.push(parsedObject);
         } catch (error) {
            console.error("Error parsing JSON object:", error);
         }
      }
      startIndex = text.indexOf('{', endIndex);
   }

   return objects;
}

export function parseBatchRespoonse(batchResponse) {
   const batchBoundary = batchResponse.match(/boundary=([^\s]+)/);
   const errors = [];

   let parsedObjects = extractAndParseObjects(batchResponse);

   if (batchBoundary) {
      const boundary = batchBoundary[1];
      const parts = batchResponse.split(`--${boundary}`);

      for (const part of parts) {
         try {
            let error = JSON.parse(part.slice(part.search(`{"error`), part.search(`}}`) + 2))
            if (error?.error) {
               errors.push(error)
            }
         } catch {

         }

      }
   }
   return { errors, objects: parsedObjects }

}