import { useEffect, useMemo, useState } from 'react';
import {
    UnorderedListOutlined,
    PushpinOutlined,
    UserOutlined,
    BarChartOutlined,
    CalendarOutlined,
    LineChartOutlined
} from '@ant-design/icons';
import SummarizeIcon from '@mui/icons-material/Summarize';

import { useGetTableSettingsQuery } from 'services/userSettings';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import useHasPrivileges, { ENTITIES, PRIVILEGES } from 'hooks/useHasPrivileges';

const menuInstant = [
    {
        id: 'group-dashboard',
        title: '',
        type: 'group',
        entity: 'WorkItem',
        privilege: PRIVILEGES.SELECT,
        color: 'third',
        children: [
            {
                id: 'dashboard',
                title: 'Assigned To Me',
                type: 'item',
                url: '/',
                icon: UserOutlined,
                breadcrumbs: false,
                color: 'third',
                items: []
            }
        ]
    },
    {
        id: 'group-all',
        title: '',
        type: 'group',
        entity: 'WorkItem',
        privilege: PRIVILEGES.SELECT,
        children: [
            {
                id: 'tasks',
                title: 'Tasks',
                type: 'item',
                url: '/tasks',
                icon: UnorderedListOutlined,
                color: 'third',
                breadcrumbs: false,
                items: []
            }
        ]
    },
    {
        id: 'weekly-log-group',
        title: '',
        type: 'group',
        entity: 'Transaction',
        privilege: PRIVILEGES.SELECT,
        children: [
            {
                id: 'weekly-log',
                title: 'Weekly Log',
                type: 'item',
                url: '/weekly-log',
                icon: CalendarOutlined,
                color: 'third',
                breadcrumbs: false,
                items: [
                    {
                        id: 'activity-log',
                        title: 'Activity Log',
                        type: 'sub-item',
                        url: '/activity-log',
                        icon: BarChartOutlined,
                        color: 'third',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    },

    {
        id: 'budget-log-group',
        title: '',
        type: 'group',
        entity: 'Budget',
        privilege: PRIVILEGES.SELECT,
        children: [
            {
                id: 'budget',
                title: 'Budget',
                type: 'item',
                url: '/budget',
                icon: LineChartOutlined,
                color: 'third',
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'reporting-log-group',
        title: '',
        type: 'group',
        entity: 'Transaction',
        privilege: PRIVILEGES.SELECT,
        children: [
            {
                id: 'reporting',
                title: 'Reporting',
                type: 'item',
                icon: SummarizeIcon,
                color: 'third',
                breadcrumbs: false,
                items: [
                    {
                        id: 'users-log',
                        title: 'Users Log',
                        type: 'sub-item',
                        url: '/users-log',
                        icon: CalendarOutlined,
                        color: 'third',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    }
    // {
    //    id: 'estimate-log-group',
    //    title: '',
    //    type: 'group',
    //    children: [
    //       {
    //          id: 'estimate',
    //          title: 'Estimate',
    //          type: 'item',
    //          url: '/estimate',
    //          icon: SummarizeIcon,
    //          color: 'third',
    //          breadcrumbs: false,
    //       },
    //    ]
    // },
];

const useMenuItems = () => {
    const userId = useSelector((state) => state.auth.userId);
    const { data, isFething } = useGetTableSettingsQuery({ userId }, { skip: !userId });
    const { allPrivileges } = useHasPrivileges();
    const { isHasPrivilege: isHasPrivilegeWorkItems } = useHasPrivileges({ entity: ENTITIES.WORK_ITEM, privilege: PRIVILEGES.SELECT });
    const [menu, setMenu] = useState(cloneDeep(menuInstant));
    const menuRaw = useMemo(() => {
        return menuInstant.filter((el) => {
            if (!('privilege' in el)) return true;
            return !!allPrivileges[el?.entity]?.[el?.privilege];
        });
    }, [allPrivileges]);

    useEffect(() => {
        if (data && data.value.length > 0 && !isFething) {
            const result = cloneDeep(menuRaw);
            data.value.forEach((item) => {
                let data;
                try {
                    data = JSON.parse(item.Data);
                } catch {
                    return;
                }
                if (data?.isProfileSettings) return;

                if (!isHasPrivilegeWorkItems) return;

                if (data?.isAssignedTable) {
                    if (result?.[0]?.children) {
                        let hasInMenu = result?.[0]?.children?.[0]?.items?.some((child) => child?.id === item.Id);
                        if (!hasInMenu) {
                            result[0].children[0].items.push({
                                id: item.Id,
                                title: item.SettingName,
                                type: 'sub-item',
                                url: `/${item.SettingName}`,
                                icon: PushpinOutlined,
                                breadcrumbs: false
                            });
                        }
                    }
                } else {
                    if (result?.[1]?.children) {
                        let hasInMenu = result?.[1].children?.[0]?.items?.some((child) => child?.id === item.Id);
                        if (!hasInMenu) {
                            result[1].children[0].items.push({
                                id: item.Id,
                                title: item.SettingName,
                                type: 'sub-item',
                                url: `/tasks/${item.SettingName}`,
                                icon: PushpinOutlined,
                                color: 'third',
                                breadcrumbs: false
                            });
                        }
                    }
                }
            });
            setMenu((_) => {
                return result;
            });
        } else {
            const result = cloneDeep(menuRaw);
            setMenu((_) => {
                return result;
            });
        }
    }, [data, isFething]);

    return menu;
};

export default useMenuItems;
