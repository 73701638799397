import { useSelector } from 'react-redux';
import { useAddTableSettingMutation, useGetTableSettingOneQuery, useUpdateTableSettingMutation } from 'services/userSettings';
import { useEffect, useState } from 'react';
import { isEmpty } from '../utils/utils';

const useUserProfileSettings = () => {
    const userId = useSelector((state) => state.auth.userId);
    const settingsName = `${userId}ProfileSettings`;

    const [addTableSetting] = useAddTableSettingMutation();
    const [updateTableSetting] = useUpdateTableSettingMutation();

    const [value, setValue] = useState({});

    const { data, isFetching, isLoading } = useGetTableSettingOneQuery({ userId, name: settingsName });

    const getValueFromJson = (json) => {
        if (!json) return {};

        try {
            return JSON.parse(json);
        } catch (e) {
            return {};
        }
    };

    useEffect(() => {
        if (!isFetching) {
            let res = getValueFromJson(data?.value?.at(0)?.Data);
            if (!res) {
                return;
            }

            setValue((prev) => {
                return { ...prev, ...res };
            });
        }
    }, [data, isFetching]);

    const updateProfileSettings = async (newValue) => {
        if ((!value || isEmpty(value)) && data?.value?.length === 0) {
            try {
                await addTableSetting({
                    SettingName: settingsName,
                    UserId: userId,
                    Data: JSON.stringify({ ...newValue, isProfileSettings: true })
                });
            } catch {}
        } else {
            try {
                await updateTableSetting({
                    id: data.value.at(0).Id,
                    etag: data.value.at(0)['@odata.etag'],
                    data: { Data: JSON.stringify({ ...value, ...newValue }) }
                });
            } catch {}
        }
    };

    return { data: value, isLoading, isFetching, updateProfileSettings };
};

export default useUserProfileSettings;
