// Helper function to create a batch request
export function createBatchRequest(callsArray) {
   const boundary = `batch_${Math.ceil(Math.random() * 1000000)}`;
   const batchRequest = [];

   for (const calls of callsArray) {
      const changesetBoundary = `changeset_${Math.random() * 1000}`;
      const changesetContent = calls.map((call, index) => {
         const content = JSON.stringify(call?.data);
         const ifMatchHeader = call?.headers['if-match'] ? `If-Match: ${call.headers['if-match']}` : '';

         return `--${changesetBoundary}
Content-Type: application/http
Content-Transfer-Encoding: binary

${call.method} ${call.url} HTTP/1.1
${ifMatchHeader}

${content ? `${content}` : ''}`}).join('\n');

      const changesetRequest = `--${boundary}
Content-Type: multipart/mixed; boundary=${changesetBoundary}

${changesetContent}
--${changesetBoundary}--`;
      batchRequest.push(changesetRequest);
   }

   batchRequest.push(`--${boundary}--`); // End the batch request

   return [batchRequest.join('\n'), boundary]
}

export const generateCall = ({ url, body, etag, method, headers = {} }) => {
   let etagFormatted = '';

   if (etag) {
      etagFormatted = etag.replaceAll('\\', '')
   }

   const res = {
      method: method,
      url: baseURL + url,
      headers: { ...headers },
      data: body ? body : { 'empty': true }

   }

   if (etagFormatted) {
      res.headers = { ...res.headers, 'if-match': etagFormatted }
   }
   return res
}