import { useState } from 'react';

export const useLocalStorage = (keyName, defaultValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.localStorage.getItem(keyName);
            if (value) {
                return JSON.parse(value);
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    });
    const setValue = (newValue) => {
        let res;
        if (typeof newValue === 'function') {
            res = newValue(storedValue);
        } else res = newValue;

        try {
            window.localStorage.setItem(keyName, JSON.stringify(res));
        } catch (err) {}
        setStoredValue(newValue);
    };
    const refetch = () => {
        try {
            const value = window.localStorage.getItem(keyName);
            if (value) {
                setStoredValue((_) => JSON.parse(value));
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                setStoredValue((_) => defaultValue);
            }
        } catch (err) {
            return defaultValue;
        }
    };
    return [storedValue, setValue, refetch];
};
